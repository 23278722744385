<template>
  <div>
    <div class="banner">
      <div class="title">
        <div>隐私政策</div>
        <div class="bottom-line"></div>
      </div>
      <div class="content-body" v-html="webData.privacyPolicy">
      </div>
    </div>
  </div>
</template>

<script>
  import { getWebsiteInfo } from '@api/websiteInfo'

  export default {
    name: 'Privacy',
    data() {
      return {
        webData: {},
        rawHtml: '<section>\n' +
          '    <h1 style="text-align: center;">湾区认证公共服务平台隐私政策</h1>\n' +
          '    <h3>引言</h3>\n' +
          '    <p>\n' +
          '        湾区认证公共服务平台（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。\n' +
          '    </p>\n' +
          '    <p>请在使用我们的产品或服务前，仔细阅读并了解本《隐私政策》，它将帮助您了解在使用我们的所有相关服务（包括但不限于网络资讯、网络互动等，以下称“服务”）时，我们如何收集、使用、存储、分享您的相关信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。<b>其中，有关你个人信息权益的条款等重要内容我们已用加粗形式提示，请特别关注。</b></p>\n' +
          '    <p>如果您不同意本隐私政策的任何内容，您可以选择停止使用我们的服务；当您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息。如对本《隐私政策》或相关事宜有任何问题，请通过 luyi@gbacpc.org.cn  或 wanym@gbacpc.org.cn与我们联系。</p>\n' +
          '    <h3>关于我们</h3>\n' +
          '\n' +
          '    <p>湾区认证公共服务平台的开发者和运营者为湾区认证促进中心。</p>\n' +
          '\n' +
          '    <h3>一、我们可能收集的信息及信息收集的途径</h3>\n' +
          '\n' +
          '    <p>我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。</p>\n' +
          '\n' +
          '    <p>（一）您向我们提供的信息</p>\n' +
          '\n' +
          '    <ul>\n' +
          '        <li>1．您在注册账号或使用我们的服务时，向我们提供的相关个人信息，例如您的手机号码；</li>\n' +
          '        <li>2．您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。</li>\n' +
          '    </ul>\n' +
          '\n' +
          '\n' +
          '    <p>（二）我们获取的您的信息</p>\n' +
          '    <h4>您使用我们的服务时我们可能收集如下信息：</h4>\n' +
          '\n' +
          '    <ul>\n' +
          '        <li>\n' +
          '        <p>1．<b>日志信息</b>指您使用我们服务时，系统可能会通过cookies 或其他类似技术方式自动采集的技术信息，包括：</p>\n' +
          '        <ul>\n' +
          '            <li>（1）设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的互联网协议（“IP”）地址和移动设备所用的版本和设备识别码；</li>\n' +
          '            <li>（2）在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页面URL地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；</li>\n' +
          '            <li>（3）有关您曾使用的移动应用（APP）和其他软件的信息，以及您曾经使用该等移动应用和软件的信息；</li>\n' +
          '            <li>（4）您通过我们的服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长；</li>\n' +
          '            <li>（5）您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等。</li>\n' +
          '        </ul>\n' +
          '        </li>\n' +
          '        <li>\n' +
          '            <p>2．<b>位置信息</b>指您开启设备定位功能并使用我们基于位置提供的相关服务时，我们会收到您的源网站和目标网站的网址。我们还会获得与您的 IP 地址、代理服务器、操作系统、网络浏览器和加载项、设备识别码及特性和/或互联网服务提供商或移动运营商有关的信息。如果您通过移动设备使用我们的“服务”，基于您的移动设备设置，设备将向我们发送您的位置数据。在使用 GPS 或其他工具确认您的准确位置之前，我们会征求您的许可。收集的有关您位置的信息，包括：</p>\n' +
          '            <ul>\n' +
          '                <li>（1）您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WIFI等方式收集的您的地理位置信息；</li>\n' +
          '                <li>（2）您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账号信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息；</li>\n' +
          '                <li>（3）您可以通过关闭定位功能，停止对您的地理位置信息的收集。</li>\n' +
          '            </ul>\n' +
          '        </li>\n' +
          '        <li>\n' +
          '            3.当您通过相机拍照或录像功能来设置头像或上传图片、视频文件时，我们将申请使用你的相机、相册权限，这是实现该等功能所必需的。\n' +
          '        </li>\n' +
          '        <li>\n' +
          '            上述服务可能需要您在您的设备中向我们授权开启您的位置（GPS）、相机（摄像头）、相册（图片库）以及麦克风等的访问权限，以实现这些服务所涉及的信息的收集和使用。您可以随时在您的手机或设备设置中决定这些权限的开启或关闭。您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述功能，您关闭权限即代表您取消了这些授权，则我们不再继续收集和使用您的个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。\n' +
          '        </li>\n' +
          '    </ul>\n' +
          '\n' +
          '    <p><b>特别提示您注意，在我们提供服务的过程中收集的能够单独或者与其他信息结合识别用户的信息，这些信息在结合使用期间，将作为你的个人信息按照本隐私政策处理与保护；如信息无法单独或结合其他信息识别到你的个人身份，其不属于法律意义上你的个人信息。</b></p>\n' +
          '    <p>4.我们将基于您本人提交且授权的注册信息（包括：姓名、身份证号码、手机号码等）并集合电信运营商的基础数据信息能力，进行用户实名认证，用户状态识别，用户画像分析，用户评级评定，反欺诈羊毛党风控，以及结合运营商提供的交际圈识别能力和位置信息类能力针对新闻评论、用户留言板进行风控管理、关联图谱建设，严控诈骗及反动活动。</p>\n' +
          '    <p>（三）信息收集的途径</p>\n' +
          '    <p><b>我们可能通过cookies和同类技术收集和使用您的信息，并将该等信息储存为日志信息，并用于以下用途：</b></p>\n' +
          '\n' +
          '    <ul>\n' +
          '        <li><b>1.记住您的身份。</b>例如：cookies有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息；</li>\n' +
          '        <li><b>2.分析您使用我们服务的情况。</b>例如，我们可利用cookies和同类技术来了解您使用我们的服务进行什么活动，或哪些网页或服务最受您的欢迎。</li>\n' +
          '    </ul>\n' +
          '\n' +
          '    <p>（四）如本平台服务决定停止运营时，我们将在相关服务停止运营后停止继续收集您的个人信息。我们将以公告形式将停止运营通知向您送达。对已持有您的个人信息，我们将进行删除或匿名化处理，除非法律法规另有规定。涉及儿童个人信息的，我们会采取必要合理方式及时将停止运营的通知及时告知儿童监护人。</p>\n' +
          '\n' +
          '\n' +
          '\n' +
          '\n' +
          '\n' +
          '\n' +
          '\n' +
          '\n' +
          '    <h3>二、我们可能如何使用您的信息</h3>\n' +
          '\n' +
          '    <p><b>我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：</b></p>\n' +
          '    <ul>\n' +
          '        <li>1. 向您提供您使用的各项服务，并维护、改善这些服务；</li>\n' +
          '        <li>2. 在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</li>\n' +
          '        <li>3. 用于定位地方频道的位置信息，如果您拒绝使用我们提供的地方频道定位服务，您可随时在【个人中心】-【更多设置】中进行关闭或取消。关闭或取消后将导致您无法使用该服务，但不影响您使用其他服务。</li>\n' +
          '        <li>4. 帮助我们设计新服务，改善我们现有服务；</li>\n' +
          '        <li>5. 软件认证或管理软件升级；</li>\n' +
          '        <li>6. 让您参与有关我们产品和服务的调查。</li>\n' +
          '    </ul>\n' +
          '    \n' +
          '\n' +
          '\n' +
          '    <h3>三、我们可能分享的信息</h3>\n' +
          '\n' +
          '    <p><b>除以下情形外，</b>未征得您的同意，我们不会向第三方共享、转让您的个人信息，或者共享、转让的个人信息是已经匿名化或去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体：</p>\n' +
          '    <ul>\n' +
          '        <li>（一）履行我们在《湾区认证公共服务平台用户注册协议》或本《隐私政策》中的义务和维护我们的合法权益，为遵守适用的法律法规、维护社会公共利益、或保护我们或我们的关联公司、我们的客户、其他用户或雇员的人身和财产安全或合法权益所合理必需的；</li>\n' +
          '        <li>（二）根据法律法规的规定或行政或政府机关、司法机构的要求；</li>\n' +
          '        <li>（三）您出现违反中国有关法律、法规规定或者违反您与我们签署的相关协议（包括在线签署的电子协议）或违反相关我们平台规则时需要向第三方披露的情形。</li>\n' +
          '        <li>（四）随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您，并按照一切适用的法律、行政法规的规定保障您的合法权益。</li>\n' +
          '    </ul>\n' +
          '\n' +
          '    <h3>四、数据共享与披露</h3>\n' +
          '\n' +
          '    <p>除下列情形外，我们不会与任何其他第三方共享您的个人信息。</p>\n' +
          '    <p>（一）在获得您的明确同意或授权或您主动选择的情况下共享。</p>\n' +
          '    <p>（二）在法定情形下的共享。我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</p>\n' +
          '    \n' +
          '    <p>（三）与第三方服务商共享。您授权并同意我们可能委托第三方服务商为您提供某些服务或代表我们履行职能，我们仅会出于本隐私信息保护政策声明的合法、正当、必要、特定、明确的目的共享您的信息。我们将在数据共享前对第三方服务商进行数据安全能力及数据共享风险进行评估，并将通过协议约束其只能接触到为其履行职责所需信息，且我们不得将您的个人信息用于其他任何目的。如超出共享目的使用您个人信息，我们将要求其另行遵循您的明确同意。</p>\n' +
          '    <p>上述相关第三方服务商收集前述信息发生信息泄露的，由相关第三方服务商承担相应的法律责任。</p>\n' +
          '    <p>请您放心，除得到您的明确同意，我们不会将可以联系或识别您身份的个人信息与授权合作伙伴共享，我们将能够识别您身份的个人信息进行去标识化处理，以便授权合作伙伴无法识别您的具体身份。</p>\n' +
          '    <h3>五、我们如何保护您的信息安全</h3>\n' +
          '\n' +
          '    <p>（一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。</p>\n' +
          '    <p>（二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>\n' +
          '    <p>（三）我们会使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。</p>\n' +
          '    <p>（四）尽管已经采取了合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</p>\n' +
          '    <p>（五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>\n' +
          '\n' +
          '    <h3>六、有关您分享的信息</h3>\n' +
          '\n' +
          '    <p>（一）您的个人信息是我们为您提供服务的重要部分，我们会遵循法律规定对您的信息承担保密义务，但可以与使用该服务的所有用户公开分享您的相关信息，例如，您在我们的服务中发布的评论或提问信息（包括您的账号昵称和头像信息）、您对其他人发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。使用我们服务的其他用户也有可能分享与您有关的信息（包括位置数据和日志信息）。只要您不删除这些信息，这些信息会一直留存在分享区域；即使您删除这些信息，有关信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或储存，或由其他用户或该等第三方在其他区域保存。</p>\n' +
          '   <p>因此，请您谨慎考虑通过我们的服务上传、发布和交流的信息内容。在一些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您共享信息的用户范围。如要求从我们的服务中删除您的相关信息，请通过该等特别服务条款提供的方式操作。</p>\n' +
          '\n' +
          '    <p>（二）某些个人信息因其特殊性可能被认为是敏感个人信息，例如您的种族、宗教、个人健康和医疗信息等。相比其他个人信息，敏感个人信息受到更加严格的保护。</p>\n' +
          '    <p><b>请注意，您在使用我们的服务时所提供、上传或发布的内容和信息（例如有关您社交活动的照片等信息），可能会泄露您的敏感个人信息。您需要谨慎地考虑，是否在使用我们的服务时披露相关敏感个人信息。</b></p>\n' +
          '\n' +
          '\n' +
          '    <h3>七、您的权利</h3>\n' +
          '    <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>\n' +
          '    <p>（一）访问您的个人信息</p>\n' +
          '    <p>您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：在【个人中心】-【账号设置】中进行查看。</p>\n' +
          '    <p>（二）更正您的个人信息</p>\n' +
          '    <p>当您发现我们处理的关于您的个人信息有错误时，您有权自己作出更正。您可以通过“（一）访问您的个人信息”中罗列的方式进行更正申请。</p>\n' +
          '    <p>（三）删除您的个人信息</p>\n' +
          '    <p><b>在以下情形中，您可以向我们提出删除个人信息的请求：</b></p>\n' +
          '    <p>1、如果我们处理个人信息的行为违反法律法规；</p> \n' +
          '    <p>2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>\n' +
          '    <p>3、如果我们处理个人信息的行为违反了与您的约定；</p>\n' +
          '    <p>4、如果您不再使用我们的产品或服务，或您注销了账号；</p>\n' +
          '    <p>5、如果我们不再为您提供产品或服务。</p>\n' +
          '    <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>\n' +
          '    <p>（四）改变您授权同意的范围</p>\n' +
          '    <p>每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，会以弹窗的方式进行通知，以获得您的授权同意。</p>\n' +
          '    <p>如果您不同意，我们将不再处理相应的个人信息。当您不同意后，不会影响此前基于您的授权而开展的个人信息处理。</p>\n' +
          '    <p>（五）个人信息主体注销账户</p>\n' +
          '    <p>您随时可注销此前注册的账户，您可以通过以下方式自行操作：在【个人中心】-【账号设置】中注销账号处，按提示进行注销。</p>\n' +
          '    <p>在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。</p>\n' +
          '    <p>（六）响应您的上述请求</p>\n' +
          '    <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>\n' +
          '    <p>我们将在三十天内作出答复。如您不满意，可以通过luyi@gbacpc.org.cn  或 wanym@gbacpc.org.cn与我们联系。</p>\n' +
          '    <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>\n' +
          '    <p><b>在以下情形中，我们将无法响应您的请求：</b></p>\n' +
          '    <p>1、与个人信息控制者履行法律法规规定的义务相关的；</p> \n' +
          '    <p>2、与国家安全、国防安全直接相关的；</p> \n' +
          '    <p>3、与公共安全、公共卫生、重大公共利益直接相关的；</p> \n' +
          '    <p>4、与刑事侦查、起诉、审判和执行判决等直接相关的；</p> \n' +
          '    <p>5、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p> \n' +
          '    <p>6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p> \n' +
          '    <p>7、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p> \n' +
          '    <p>8、涉及商业秘密的。</p>\n' +
          '\n' +
          '\n' +
          '    <h3>八、您的个人信息如何在全球范围转移</h3>\n' +
          '    <p>原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</p>\n' +
          '    <p>由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。</p>\n' +
          '    <p>此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</p>\n' +
          '\n' +
          '\n' +
          '\n' +
          '    <h3>九、未成年人条款</h3>\n' +
          '\n' +
          '    <p><b>若您是未满 18 周岁的未成年人，在使用我们相关服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。</b></p>\n' +
          '    <p>我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、储存、共享、转让或披露未成年人的个人信息。</p>\n' +
          '\n' +
          '    <h3>十、本《隐私政策》的适用范围及例外</h3>\n' +
          '\n' +
          '    <p>（一）除某些特定服务外，我们所有的服务均适用本《隐私政策》。这些特定服务将适用特定的隐私政策。针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。该特定服务的隐私政策构成本《隐私政策》的一部分。如相关特定服务的隐私政策与本《隐私政策》有不一致之处，适用该特定服务的隐私政策。</p>\n' +
          '    <p>（二）我们的服务可能包括或链接至第三方提供的社交媒体或其他服务（包括网站），该等第三方社交媒体或其他服务可能由相关的第三方或我们运营。您使用该等第三方的社交媒体服务或其他服务（包括您向该等第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策约束，您需要仔细阅读其条款。</p>\n' +
          '    <p>本《隐私政策》仅适用于我们所收集的信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方使用由您提供的信息不承担任何责任，无论你登录、浏览或使用上述软件、网站是否基于我们的链接或引导。</p>\n' +
          '\n' +
          '    <h3>十一、本《隐私政策》的修订和通知</h3>\n' +
          '\n' +
          '    <p>为给您提供更好的服务，我们会发出更新版本并提醒您相关内容的更新，我们可能适时修订本《隐私政策》的条款，该等修订构成本《隐私政策》的一部分。</p>\n' +
          '    <p>未经您明确同意，我们不会削减您按照本个人信息保护政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。对于重大变更，我们还会在修订生效前通过在主页上显著位置提示或以其他方式通知您。在该种情况下，您可以选择停止使用我们的服务；若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。本政策所指的重大变更包括但不限于：</p>\n' +
          '    <p>（一）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p> \n' +
          '    <p>（二）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>\n' +
          '    <p>（三）个人信息共享、转让或公开披露的主要对象发生变化；</p>\n' +
          '    <p>（四）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>\n' +
          '    <p>（五）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。</p>\n' +
          '\n' +
          '    <h3>十二、如何联系我们</h3>\n' +
          '    <p>如果您对本个人信息保护政策有任何疑问、意见或建议，通过luyi@gbacpc.org.cn  或 wanym@gbacpc.org.cn与我们联系。</p>\n' +
          '    <p>本协议签订地为中华人民共和国北京市西城区，因本协议生效、履行、解释及使用我们的平台或服务而产生或与本协议相关的一切争议、权利主张或其他事项，均适用中华人民共和国法律（为本协议之目的，港澳台地区法律不在此列）。您与我们及其关联公司或我们产品或服务发生的一切争议，应友好协商，如协商不成的，您同意双方通过将争议提交本协议签订地有管辖权的法院解决。</p>\n' +
          '    <br>\n' +
          '    <p><b>您再次确认您已全部阅读并充分理解上述条款。</b></p>\n' +
          '</section>'
      }
    },
    mounted() {
    },
    created() {
      this.loadWebsiteData()
    },
    methods: {
      loadWebsiteData() {
        let data = {}
        getWebsiteInfo().then(res => {
          if (!res.success) {
            console.error(res.message)
            return
          }
          data = res.result[0]
        }).finally(() => {
          // 将数据存入vuex中
          this.$store.commit('WEB_DATA', data)
          this.webData = this.$store.state.webData
        })
      }
    }
  }
</script>

<style scoped lang="less">
.banner {
  width: 1000px;
  height: 100%;
  margin: 85px auto 0;

  .title {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 33px 0;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }
}
</style>
